/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  // { id: 'enus', name: 'English - US', direction: 'ltr' },
  { id: 'en', name: 'English', direction: 'ltr' },
  //{ id: 'es', name: 'Español', direction: 'ltr' },
  //{ id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const appRoot = '/app';
export const searchPath = `${appRoot}/#`;
export const apiHost = 'http://localhost:7000/v1';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.creo';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const colors = [
  'creo',
  'bluenavy',
  // 'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
