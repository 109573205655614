import axios from 'axios';
import { apiHost } from '../constants/defaultValues';

export class AuthService {
  constructor() {
    this.http = axios;
    this.apiHost = apiHost;
    this.endpoint = 'auth';
  }

  async login(username, password) {
    const result = await this.http.post(
      `${this.apiHost}/${this.endpoint}/login`,
      {
        username,
        password,
      }
    );

    return result.data;
  }
}

export default new AuthService();
