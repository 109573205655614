import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import AuthService from '../../services/AuthService';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  API_ERROR,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  // registerUserSuccess,
  // registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  // logoutUser,
} from './actions';

import { appRoot } from '../../constants/defaultValues';
import { setAccessToken, setCurrentUser } from '../../helpers/Utils';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithUsernamePassword);
}

const loginWithUsernamePasswordAsync = async (username, password) =>
  await AuthService.login(username, password)
    .then((user) => user)
    .catch((error) => error);

function* loginWithUsernamePassword({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;

  try {
    const loginUser = yield call(
      loginWithUsernamePasswordAsync,
      username,
      password
    );

    if (!loginUser.message) {
      setCurrentUser(loginUser.user); // Local storage
      setAccessToken(loginUser.access_token); // Local storage
      yield put(loginUserSuccess(loginUser.user, loginUser.access_token)); // Store
      history.push(appRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

// const registerWithEmailPasswordAsync = async (email, password) =>
//   await AuthService.createUserWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  // const { email, password } = payload.user;
  // const { history } = payload;
  // try {
  //   const registerUser = yield call(
  //     registerWithEmailPasswordAsync,
  //     email,
  //     password
  //   );
  //   if (!registerUser.message) {
  //     const item = { uid: registerUser.user.uid, ...currentUser };
  //     setCurrentUser(item);
  //     yield put(registerUserSuccess(item));
  //     history.push(appRoot);
  //   } else {
  //     yield put(registerUserError(registerUser.message));
  //   }
  // } catch (error) {
  //   yield put(registerUserError(error));
  // }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  // await AuthService.logout() // Not relevant
  //   .then((user) => user)
  //   .catch((error) => error);
  if (history) {
    history.push(appRoot);
  }
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  setAccessToken();

  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await AuthService.sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await AuthService.confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchApiError() {
  yield takeEvery(API_ERROR, apiError);
}

function* apiError({ payload }) {
  // Handle unauthorised permissiono errors
  if (payload.error.response.status === 401) {
    // @dirty
    setCurrentUser();
    setAccessToken();

    yield call(logoutAsync);
    window.location = appRoot; // Dirty, very dirty
    // EO @dirty
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchApiError),
  ]);
}
