// import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducers from './rootReducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export function configStore(initialState) {
  const store = configureStore({
    reducer: rootReducers,
    middleware: middlewares,
    preloadedState: initialState, // Not currently used
  });

  sagaMiddleware.run(sagas);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducers', () => {
      const nextRootReducer = require('./rootReducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
