// import { combineReducers } from '@reduxjs/toolkit';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import { reducer as tennants } from '../features/tennants/redux/reducer';
import { reducer as accounts } from '../features/accounts/redux/reducer';
import { reducer as users } from '../features/users/redux/reducer';
import { reducer as sites } from '../features/sites/redux/reducer';

const rootReducers = {
  menu,
  settings,
  authUser,
  tennants,
  accounts,
  users,
  sites,
};

export default rootReducers;
