import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT_USER } from '../redux/actions';
class CrudReducerFactory {
  constructor(slice, state = null, initialState = {}) {
    state = state || slice;

    this.initialState = initialState;

    const reducerResult = createSlice({
      name: slice,
      initialState: initialState[state],
      reducers: this._generateReducers(),

      extraReducers: (builder) => {
        builder.addCase(LOGOUT_USER, (state, action) => {
          return { ...this.initialState };
        });

        // Default case if no other handlers matched
        //.addDefaultCase((state, action) => {});
      },
    });

    this.reducer = reducerResult.reducer;
    this.actions = reducerResult.actions;
  }

  _generateReducers = () => {
    return {
      // Create One
      requestCreateOne: (state, action) => {
        state.isLoading = true;
      },
      requestCreateOneSuccess: (state, action) => {
        state.isLoading = false;
        state.one = action.payload;
      },
      requestCreateOneError: (state, action) => {
        state.isLoading = false;
      },

      // Get Many
      requestGetMany: (state, action) => {
        state.isLoading = true;
      },
      requestGetManySuccess: (state, action) => {
        state.isLoading = false;

        // Hacky demo
        action.payload.data = action.payload.data.map((x) => {
          return {
            ...x,
            img: `https://picsum.photos/seed/${x.id}/300/300.webp`,
          };
        });
        // EO hack
        state.many = action.payload;
      },
      requestGetManyError: (state, action) => {
        state.isLoading = false;
      },

      // Get One
      requestGetOne: (state, action) => {
        state.isLoading = true;
      },
      requestGetOneSuccess: (state, action) => {
        state.isLoading = false;
        state.one = action.payload;
      },
      requestGetOneError: (state, action) => {
        state.isLoading = false;
      },
    };
  };
}

export default CrudReducerFactory;
